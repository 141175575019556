import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../frontend/backend/navbar.js";
import Footer from "../frontend/backend/footer.js";
import Chatbox from "../frontend/backend/chatbox.js";
import HeaderRight from "../frontend/backend/header_right.js";
import LeftSidebar from "../frontend/backend/leftSidebar.js";
import axios from "axios";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import apiConfig from '../apiConfig';
import CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";

const DownlineList = ({ match }) => {
  const down_userid = match.params.userid;
  const token = localStorage.getItem("jwtToken");
  const decodedToken = jwt_decode(token);
  const userInfo = decodedToken;
  const user_role = userInfo.role_as;
  
  const preventMinus = (e) => {
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  

  const secretKey       = 'Maxx12#'; 
  let history           = useHistory();

  const [data, setData]      = useState([]);
  
  const encryptUserId = (userId) => {
    const encrypted = CryptoJS.AES.encrypt(userId, secretKey).toString();
    const urlSafeEncrypted = encrypted
      .replace(/\//g, '_')  
      .replace(/\+/g, '-') 
      .replace(/=/g, '');   
    return urlSafeEncrypted;
  };

  useEffect(() => {
    getPaginatedUsers();
  }, []);


  const getPaginatedUsers = async () => {
    try {
    
      const response = await axios.get(
        `${apiConfig.baseURL}/api/agent/user_details?down_userid=${down_userid}`
      );

      const  responsedata= response.data;
      setData(responsedata);
    } catch (error) {
      // Handle errors
      if (error.response) {
        // Error response from the server
        if (error.response.status === 400) {
          console.error("Error 400: User ID is invalid.");
          alert("Error 400: User ID is invalid.");
        } else {
          console.error("An error occurred:", error.response.data.message);
          alert(`Error: ${error.response.data.message}`);
        }
      } else if (error.request) {
        // No response from the server
        console.error("No response received:", error.request);
        alert("No response from the server. Please try again later.");
      } else {
        // Any other errors
        console.error("Unexpected error:", error.message);
        alert(`Unexpected error: ${error.message}`);
      }
    }
  };
  
  const [inpval, setINP] = useState({
    agent_id: userInfo.user_id,
    amount: "",
    s_key: "",
    balance_add_remove: "",
    user_id: "",
  });

  const setdata = (e) => {
    const { name, value } = e.target;
    setINP((preval) => ({
      ...preval,
      [name]: value,
    }));
  };

 
  const [isLoading, setIsLoading] = useState(false);

    const addinpdata = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const { amount, s_key, agent_id, agentEmail, balance_add_remove } = inpval;
      const user_id = data?.user_id;
      
     
      let missingFields = [];

      if (!balance_add_remove) missingFields.push("Select Method");
      if (!user_id) missingFields.push("User ID");
      if (!amount) missingFields.push("Amount");
      if (!s_key) missingFields.push("Security Pin");
      if (!agent_id) missingFields.push("Agent ID");

      if (missingFields.length > 0) {
        alert(`Please input the following fields:\n- ${missingFields.join("\n- ")}`);
        setIsLoading(false);
        return ; 
      }
      
      try {
        const response = await axios.post(`${apiConfig.baseURL}/api/agent/balance_add_deduct_user`, {
          balance_add_remove,
          user_id,
          amount,
          s_key,
          agent_id,
          agentEmail,
        });
    
        if (response.status === 200) {
          alert("Transfer Successfully");
          history.push("/user-balance-report");
        } else {
          alert("An unexpected error occurred.");
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.error("Error Response:", error.response);
          setIsLoading(false);
        }
        if (error.response) {
          const { status, data } = error.response;
          setIsLoading(false);
          switch (status) {
            case 400:
              alert("Incorrect Security T-PIN");
              break;
            case 404:
              alert("This User does not exist");
              break;
            case 422:
              alert(data || "Validation error occurred.");
              break;
            default:
              alert(data?.message || "An unexpected error occurred.");
          }
        } else {
          alert("Network error. Please try again.");
        }
      }
      
    };
  

  return (
    <>
      <Navbar />
      <Chatbox />
      <HeaderRight />
      <LeftSidebar />

        <div class="content-body">
        <div class="container-fluid">
          <div class=" col-lg-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Balance Transfer</h4>

                <Link to="/user-balance-report">
                  <button type="button" className="btn btn-success float-right">
                  Report
                  </button>
                </Link>

              </div>
              <div class="card-body">
                <div class="basic-form">
                  <form>
                    
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">Select Method</label>
                      <div class="col-sm-9">
                      <select
                          className="form-control"
                          required
                          name="balance_add_remove"
                          value={inpval.balance_add_remove}
                          onChange={setdata}
                        >
                          <option value="">Select Deposit Or Withdraw</option>
                          <option value="add">Deposit</option>
                          <option value="remove">Withdraw</option>
                        </select>
                      </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-3 col-form-label">USER ID</label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="user_id"
                            className="form-control"
                            placeholder="Admin Id"
                            onChange={setdata}
                            value={data?.user_id || ""}
                          />
                        </div>
                      </div>

                    
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">Amount</label>
                      <div class="col-sm-9">
                       <input
                          type="number"
                          name="amount"
                          className="form-control"
                          placeholder="Amount"
                          onChange={setdata}
                          value={inpval.amount}
                          onKeyDown={preventMinus}
                          min={0}
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">
                        Security Pin
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="password"
                          name="s_key"
                          required
                          class="form-control"
                          placeholder="Security Pin"
                          onChange={setdata}
                          value={inpval.s_key}
                        />
                      </div>
                    </div>


                    <input
                      type="hidden"
                      name="agentEmail"
                      onChange={setdata}
                      value={userInfo.email}
                    />

                    <input
                      type="hidden"
                      name="agent_id"
                      onChange={setdata}
                      value={userInfo.user_id}
                    />

                    <div class="mb-3 row" align="right">
                      <div class="col-sm-12">
                        <button
                            type="submit"
                            name="send"
                            onClick={addinpdata}
                            className="btn btn-primary"
                            disabled={isLoading}
                          >
                            {isLoading ? "Processing..." : "Submit"}
                          </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <Footer />
    </>
  );
};

export default DownlineList;
